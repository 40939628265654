export default {
  blogerName: 'RAMCHA',
  socialsList: [
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/ramcha1337'
    },
    {
      name: 'telegram',
      url: 'https://t.me/ramcha1337'
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/ce6951180',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/c9071f706',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c41c394a2',
      gameTitle: 'Kali Rox (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c6802ac2f',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cd518f371',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c1c3c4dc0',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c4e483edb',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/cb6d43e77',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c8c8a7d55',
      gameTitle: 'Doors Of Sol (BGaming)',
    },

    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c37e9cab9',
      gameTitle: 'Doors Of Fresh (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>RAMCHA</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'RAMCHA',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
